import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  HasErrorPartial,
  HelpTextPartial,
  InputDisplayTypePartial,
  Link,
  List,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
  SpacePartial,
  IsReadOnlyPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const radioSnippet = require('raw-loader!../../../../data/snippets/rn-radio.example');
const radioGroupSnippet = require('raw-loader!../../../../data/snippets/rn-radio-group.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Single Radio', 'Radio Group']} />
      <Section title="Single Radio">
        <CodeSnippet
          disableCodeEditing
          code={radioSnippet}
          platform="react-native"
          gitHubLink="forms/radio"
        />

        <Section title="Props">
          <PropList header="Visual">
            <InputDisplayTypePartial />

            <PropListItem name="isChecked" types={['boolean']}>
              <Text>Determines whether the radio is checked.</Text>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>Determines whether the radio is disabled.</Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="radio" />

            <PropListItem name="label" types={['string']} isRequired>
              <Text>The label that appears to the right of the radio.</Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the radio's size.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>
          </PropList>

          <HasErrorPartial componentName="radio" />

          <PropList header="Functional">
            <PropListItem name="value" types={['string']} isRequired>
              <Text>The value bound to the radio.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['() => void']}>
              <Text>Responds to the radio press event.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="radio" />

            <TestIdPartial componentName="radio" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Do not pass the <code>checked</code> attribute if you're using
              this component within a RadioGroup. Instead, use that component's{' '}
              <code>valueChecked</code> prop.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Radio"
        />
      </Section>

      <Section title="Radio Group">
        <Paragraph>
          The Radio Group is the parent element of related radios from which a
          user is expected to choose one of the options.
        </Paragraph>

        <CodeSnippet
          disableCodeEditing
          code={radioGroupSnippet}
          platform="react-native"
          gitHubLink="forms/radio-group"
        />

        <Section title="Props">
          <PropList header="Visual">
            <InputDisplayTypePartial />

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of radios.</Text>
            </PropListItem>

            <HelpTextPartial componentName="radio group" />

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>
                Determines whether the entire radio group is disabled.
              </Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="radio group" />

            <PropListItem name="isRequired" types={['boolean']}>
              <Text>
                Determines whether the required text appears next to the header.
              </Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the radio's size.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <HasErrorPartial componentName="radio group" />

            <SpacePartial />

            <PropListItem name="valueChecked" types={['string']}>
              <Text>The value checked.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['(value: string) => void']}>
              <Text>
                Responds to the radio being tapped. The value of the tapped
                radio is passed.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="radio group" />

            <TestIdPartial componentName="radio group" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Radio Group"
        />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
