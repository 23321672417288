import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  HasErrorPartial,
  HelpTextPartial,
  InputDisplayTypePartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  Link,
  List,
  OrientationPartial,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Radio = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Radio;
    return <Component {...props} />;
  },
});

const RadioGroup = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.RadioGroup;
    return <Component {...props} />;
  },
});

const radioSnippet = require('raw-loader!../../../../data/snippets/radio.example');
const radioGroupSnippet = require('raw-loader!../../../../data/snippets/radio-group.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Radio"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-radios--docs"
      />
      <PageNavigation links={['Single Radio', 'Radio Group']} />
      <Section title="Single Radio">
        <CodeSnippet
          scope={{ React: React, Radio: Radio }}
          code={radioSnippet}
          platform="react"
          gitHubLink="forms/radio"
        />

        <Section title="Props">
          <PropList header="Visual">
            <ClassnamePartial componentName="radio" />

            <InputDisplayTypePartial />

            <PropListItem name="isChecked" types={['boolean']}>
              <Text>Determines whether the radio is checked.</Text>
            </PropListItem>

            <IsDisabledPartial componentName="radio" />

            <IsReadOnlyPartial componentName="radio" />

            <PropListItem name="label" types={['string']}>
              <Text>The label that appears to the right of the radio.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="radio" passedDown={['label']} />
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Do not pass the <code>checked</code> attribute if you're using
              this component within a <code>RadioGroup</code>. Instead, use that
              component's <code>valueChecked</code> prop.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Radio"
        />
      </Section>
      <Section title="Radio Group">
        <Paragraph>
          The Radio Group is the parent element of related radios from which a
          user is expected to choose one of the options.
        </Paragraph>

        <CodeSnippet
          scope={{ React: React, Radio: Radio, RadioGroup: RadioGroup }}
          code={radioGroupSnippet}
          platform="react"
          gitHubLink="forms/radio-group"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['string']} isRequired>
              <Text>The radio buttons to display in the group.</Text>
            </PropListItem>

            <ClassnamePartial componentName="radio group" />

            <InputDisplayTypePartial />

            <EnvironmentPartial />

            <FormSizePartial componentName="radio group" />

            <HasErrorPartial componentName="radio group" />

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of radios.</Text>
            </PropListItem>

            <HelpTextPartial componentName="radio group" />

            <IsDisabledPartial componentName="radio group" />

            <IsReadOnlyPartial componentName="radio group" />

            <IsRequiredPartial componentName="radio group" />

            <OrientationPartial
              componentName="radio group"
              defaultOrientation="vertical"
            />

            <PropListItem name="valueChecked" types={['string', 'number']}>
              <Text>The current value checked in the group.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Functional">
            <PropListItem name="name" types={['string']}>
              <Text>The name attribute attached to the radio group.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onChange"
              types={['(value: string | number) => void']}>
              <Text>
                Responds to the radio being checked or unchecked. The value of
                the clicked radio is passed.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial
              componentName="radio group"
              passedDown={['header', 'help-text']}
            />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from an HTML{' '}
                <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio">
                  radio
                </Link>{' '}
                element.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Radio Group"
        />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
